import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';

import PageTitle from '../components/page-title';
import Bios from '../components/bios';
import TextCallout from '../components/text-callout';
import Quotes from '../components/quotes';

const WhoWeAre = ({ data }) => {
  const pageContent = data.allPrismicWhoWeAre.edges[0].node.data;

  return (
    <Layout>
      <SEO title={pageContent.page_title.text} />
      <PageTitle
        title={pageContent.page_title.text}
        spacing="u-mar-top-xxl u-mar-bottom-xl md:u-mar-top-xxxl sm-md:u-mar-bottom-xxl md:u-pad-bottom-m"
      />

      <div className="o-container-fluid u-mar-vert-m md:u-mar-vert-xl lg:u-mar-bottom-xxl">
        <Bios bios={pageContent.founder_bios} />
      </div>

      <Quotes data={pageContent.body[0]} theme={'yellow-bottom'} isSlice />
      <TextCallout data={pageContent.body[1]} />
    </Layout>
  );
};

export default withPreview(WhoWeAre);

export const query = graphql`
  {
    allPrismicWhoWeAre {
      edges {
        node {
          prismicId
          data {
            page_title {
              text
            }
            founder_bios {
              founder {
                document {
                  ... on PrismicBio {
                    data {
                      bio_name {
                        text
                      }
                      bio_title {
                        text
                      }
                      bio_linkedin {
                        url
                      }
                      full_bio {
                        html
                      }
                      bio_image {
                        fluid(maxWidth: 800) {
                          ...GatsbyPrismicImageFluid
                        }
                        alt
                      }
                    }
                  }
                }
              }
            }
            body {
              ... on PrismicWhoWeAreBodyQuotes {
                primary {
                  quotes_reference {
                    document {
                      ... on PrismicQuotes {
                        data {
                          quote {
                            quote_text {
                              html
                            }
                            quote_author {
                              html
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicWhoWeAreBodyTextCallout {
                slice_label
                primary {
                  portfolio_title {
                    text
                  }
                  portfolio_body {
                    html
                  }
                  portfolio_sidebar {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
