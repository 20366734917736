import React from 'react';

import './bios.module.scss';

import linkedIn from '../../images/linkedin.svg';

const Bios = ({ bios }) => (
  <ul className="o-row">
    {bios.map((item, i) => {
      const bio = item.founder.document.data;

      return (
        <li
          className={`
                    o-col
                    o-col-sm-6
                    o-col-md-5
                    o-col-xl-5
                    o-col-md--offset-${i === 0 ? 1 : 0}
                    `}
          key={i}
          styleName="bio"
        >
          <div className="u-aspect-ratio is-1x1 u-img-cover u-mar-bottom-s xl:u-mar-bottom-l">
            <img src={bio.bio_image.fluid.src} alt={bio.bio_image.alt} />
          </div>
          <h3 className="title--small">{bio.bio_name.text}</h3>
          <p>{bio.bio_title.text}</p>
          <a href={bio.bio_linkedin.url} styleName="bio__linkedin">
            <img src={linkedIn} alt="LinkedIn" />
            LinkedIn
          </a>
          <div
            dangerouslySetInnerHTML={{ __html: bio.full_bio.html }}
            styleName="bio__overview"
          />
        </li>
      );
    })}
  </ul>
);

export default Bios;
