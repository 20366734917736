import React from 'react';

import './page-title.module.scss';

const PageTitle = ({ title, spacing }) => {
  return (
    <div className={`o-container-fluid ${spacing ? spacing : ''}`}>
      <div className="u-align-center">
        <h1 className="title title--xl" styleName="page-title">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default PageTitle;
